.rbc-allday-cell {
  display: none !important;
}
.rbc-row .rbc-time-header-cell .rbc-time-header-cell-single-day {
  display: none !important;
}
.rbc-timeslot-group {
  min-height: 150px;
  min-width: 10px;
}
.rbc-time-header {
  display: none;
}
.rbc-time-gutter {
  display: none;
}
.rbc-toolbar {
  display: none;
}
.rbc-time-content {
  border: none;
  overflow-y: unset !important;
}
.rbc-time-view {
  border: none;
}

.rbc-time-view {
  width: 100%;
}
.rbc-day-slot .rbc-events-container {
  margin-right: 0 !important;
}
.rbc-slot-selection {
  display: none;
}
.rbc-day-slot {
  padding: none !important;
}
.rbc-event-label {
  display: none;
}

.rbc-event {
  padding: 0 !important;
}
.rbc-today {
  background-color: white;
}
.rbc-event-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 5px 3px 5px;
}
.rbc-current-time-indicator {
  display: none !important;
  border: 1px solid #db1e1e;
}
.hare:hover {
  opacity: 0.1;
}

.scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #dfe3e8 !important;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #dfe3e8 !important;
}
