.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}
.description {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
}
