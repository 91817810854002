.login_wrapper {
  border-radius: 16px;
  max-width: 522px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  @media only screen and (max-width: 500px) {
    border-radius: 0px;
    box-shadow: unset;
  }
}

.layout_container {
  background: linear-gradient(140.03deg, #37496c 1.85%, #1b2130 85.49%);

  @media only screen and (max-width: 500px) {
    padding: 0px;
    background: white;
  }
}

.logo_wrapper {
  @media only screen and (max-width: 500px) {
    width: 100vw;
    padding-top: 100px !important;
    text-align: center;
    padding: 0px;
    border-bottom-left-radius: 50%;
    background: linear-gradient(140.03deg, #37496c 1.85%, #1b2130 85.49%);
  }
}

.logo {
  @media only screen and (max-width: 500px) {
    width: 240px;
  }
}
