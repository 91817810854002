.container {
  justify-content: center;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.character {
  max-width: 43px;
  border-radius: 10px;
  line-height: 50px;
  margin-left: 8px;
  font-weight: 500;
  font-size: 32px;
  color: #212b36;
  border: 1.5px solid #1976d2;
}

.character:first-child {
  margin-left: 0;
}

.character--selected {
  border: 2px solid #1976d2;
  background: rgba(255, 255, 255, 0.984);
}

.character--inactive {
  border: 1px solid #1976d2;
  background: #77c4ff2b;
}

.success_sent {
  @media only screen and (max-width: 500px) {
    height: 60vh;
  }
}

.login_ontainer {
  @media only screen and (max-width: 500px) {
    height: 65vh;
    width: 100%;
  }
}

.recaptcha {
  @media only screen and (max-width: 500px) {
    margin-left: 0px;
  }
}
