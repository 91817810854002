@import '@react-awesome-query-builder/ui/css/styles.css';

.group {
  background-color: #f9fafb !important;
  border: 0 !important;
}

.rule--body {
  gap: 16px !important;
}

.rule--operator,
.widget--widget {
  margin-left: 0;
}

.rule--header {
  opacity: 1 !important;
}

.rule--header button {
  color: #db1e1e;
}

.MuiButton-containedSecondary {
  background-color: #066fa3 !important;
}

.rule--operator {
  max-width: 120px;
  width: 100%;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiDialog-paper {
  width: fit-content !important;
  border-radius: 8px !important;
}

.rule--widget {
  max-width: 400px;
  overflow: unset;
  display: block !important;
}

.group--actions .MuiButton-root {
  color: #1976d2;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  left: 50% !important;
  width: 280px;
}
.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:last-of-type {
  max-width: 450px;
}
