@media print {
  .print-container {
    position: relative;
    page-break-before: auto;
  }

  .header,
  .footer {
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
  }

  .header {
    top: 0;
    height: '150px';
  }

  .footer {
    bottom: 0;
    height: '104px';
  }

  .content-to-print {
    page-break-after: auto;
    padding-bottom: '120px !important';
  }

  .confirmation-header {
    background: #eceef1 !important;
    background-color: #eceef1 !important;
  }
}
